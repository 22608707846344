import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import add from 'date-fns/add';
import { de } from 'date-fns/locale';

/**
 * Constants
 */
export const INTERNAL_DATE_FORMAT = 'yyyy-MM-dd';

export const defaultFormat = (date: Date): string => {
  return format(date, INTERNAL_DATE_FORMAT);
};

export const getDurationString = (duration: number) => {
  const durationString = duration === 0.5 ? '½' : duration;

  return `${durationString} Tag${duration > 1 ? 'e' : ''}`;
};

export const getEndDate = (
  startDate: Date | string,
  duration: number,
): Date => {
  let start = startDate;
  const addDays = duration <= 1 ? 0 : duration - 1;

  if (!(start instanceof Date)) {
    start = parseISO(start);
  }

  return add(start, { days: addDays });
};

export const formatDateRange = (
  start: Date | string,
  end: Date | string,
): string => {
  let startString: string;

  if (!(start instanceof Date)) {
    start = parseISO(start);
  }

  if (!(end instanceof Date)) {
    end = parseISO(end);
  }

  if (start.getMonth() === end.getMonth()) {
    startString = format(start, 'd.', { locale: de });
  } else {
    startString = format(start, 'd. MMM', { locale: de });
  }

  const endString = format(end, 'd. MMM', { locale: de });

  let rangeString: string;

  if (start.toISOString() === end.toISOString()) {
    rangeString = `${endString}`;
  } else {
    rangeString = `${startString} - ${endString}`;
  }

  return rangeString;
};

export const formatTime = (date: Date | string): string => {
  if (!(date instanceof Date)) {
    date = parseISO(date);
  }

  return format(date, 'HH:mm:ss');
};
