import React, { FC, useState } from 'react';
import {
  Button,
  createStyles,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import { isSameDay, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';
import { makeStyles } from '@material-ui/core/styles';
import { useGlobalStyles } from '../../styles/global';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { AddCircle } from '@material-ui/icons';

export interface IClosedDate {
  id: string;
  closedDate: string;
  locationId: string;
}

export interface IClosingDatesFormProps {
  closingDates: IClosedDate[];
  onDeleteDate: (id: string) => void;
  onInsertDate: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
}

interface IClosingDatesListProps {
  closingDates: IClosedDate[];
  onDeleteDate: (id: string) => void;
}

const ClosingDatesList: FC<IClosingDatesListProps> = ({
  closingDates,
  onDeleteDate,
}) => {
  return (
    <>
      <List dense={true}>
        {closingDates &&
          closingDates.map(date => {
            // @todo: Use global i18n date format or I18N from browser?
            const dateString = date.closedDate;

            return (
              <ListItem key={date.id}>
                <ListItemText primary={dateString} />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => onDeleteDate(date.id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
      </List>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closed: {
      '& button': {
        backgroundColor: 'red',
      },
    },
    open: {},
  }),
);

export const ClosingDates: FC<IClosingDatesFormProps> = ({
  closingDates,
  onDeleteDate,
  onInsertDate,
  minDate,
  maxDate,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const getClosedDate = (date: Date | undefined): IClosedDate | undefined => {
    if (!date) {
      return;
    }

    return closingDates.find(d => isSameDay(parseISO(d.closedDate), date));
  };

  const handleAddDate = (): void => {
    if (!selectedDate) {
      return;
    }

    const closedDate = getClosedDate(selectedDate);
    if (closedDate) {
      return;
    }

    onInsertDate(selectedDate);
  };

  const handleChangeDate = (date: Date): void => {
    setSelectedDate(date);
  };

  const isDisabledDay = (day: Date) => {
    return !!closingDates.find(d => isSameDay(parseISO(d.closedDate), day));
  };

  // @todo: Check if date has active bookings. If yes, disable this date for handleToggleDate and display a tooltip which informs
  // the user to first cancel those bookings before changing date to closed.
  const renderCalendarDay = (
    day: Date,
    selectedDate: Date,
    dayInCurrentMonth: boolean,
    DayComponent: JSX.Element,
  ): JSX.Element => {
    const isClosed = isDisabledDay(day);

    return (
      <span className={isClosed ? classes.closed : classes.open}>
        {DayComponent}
      </span>
    );
  };

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
            <DatePicker
              disableToolbar
              autoOk
              variant='inline'
              value={selectedDate}
              onChange={handleChangeDate}
              maxDate={maxDate}
              minDate={minDate}
              renderDay={renderCalendarDay}
              shouldDisableDate={isDisabledDay}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={4}>
          <IconButton
            aria-label='Datum hinzufügen'
            color='primary'
            onClick={handleAddDate}
          >
            <AddCircle fontSize={'small'} />
          </IconButton>
        </Grid>
      </Grid>

      <Typography variant={'subtitle1'}>Geschlossene Tage</Typography>
      <ClosingDatesList
        closingDates={closingDates}
        onDeleteDate={onDeleteDate}
      />
    </>
  );
};
