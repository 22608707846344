import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import theme from './styles/theme';
import { Auth0Provider } from '@auth0/auth0-react';
import ApolloWrapper from './components/ApolloWrapper';

const domain: string | undefined = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId: string | undefined = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience: string | undefined = process.env.REACT_APP_AUTH0_AUDIENCE;

if (!domain || !clientId || !audience) {
  throw new Error('.env vars missing');
}

ReactDOM.render(
  <>
    {domain && clientId && audience && (
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={window.location.origin}
        audience={audience}
      >
        <ApolloWrapper>
          <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <App />
          </ThemeProvider>
        </ApolloWrapper>
      </Auth0Provider>
    )}
  </>,
  document.querySelector('#root'),
);
