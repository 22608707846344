import React, { FC, ReactElement, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  Input,
  Paper,
  Theme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { useGlobalStyles } from '../../styles/global';
import {
  useGetSeasonsQuery,
  useInsertProductTransactionsMutation,
  useInsertTransactionMutation,
  useObserveProductSubscription,
  useObserveProductTransactionResultSubscription,
} from '../../api/generated';
import { defaultFormat } from '../../helpers/date';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { de } from 'date-fns/locale';
import WarningIcon from '@material-ui/icons/Warning';
import { parseISO } from 'date-fns';

type ProductSettingsProps = {
  date: string;
  productId: string;
};

const ProductSettings: FC<ProductSettingsProps> = ({
  date,
  productId,
}): ReactElement => {
  /** Styles */
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  /** GraphQL */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data } = useObserveProductSubscription({
    variables: {
      date: date,
      productId: productId,
    },
  });

  const [insertTransaction] = useInsertTransactionMutation();
  const [insertProductTransactions] = useInsertProductTransactionsMutation();

  /**
   * Local state
   */
  const product = data?.products_by_pk;
  const [showBikesAvailableForm, setShowBikesAvailableForm] = useState(false);
  const [bikesAvailable, setBikesAvailable] = useState<number | null | undefined>(product?.bikesAvailable);
  const [productTransactionId, setProductTransactionId] = useState<string | undefined>();
  const [availabilityStartDate, setAvailabilityStartDate] = useState<Date>(
    new Date(),
  );
  const [availabilityEndDate, setAvailabilityEndDate] = useState<Date>(
    new Date(),
  );

  /**
   * GraphQL
   */
  let observedStatus;
  let hasObservedResultError = false;
  let observedResultLoading = false;

  /** Transactions */
  const {
    data: observeProductTransactionResultData,
    error: observeProductTransactionResultError,
  } = useObserveProductTransactionResultSubscription({
    variables: {
      id: productTransactionId,
    },
  });

  /** Seasons */
  const {
    data: seasonsData,
  } = useGetSeasonsQuery();

  const observedResult =
    observeProductTransactionResultData?.productTransactions_by_pk?.result;

  if (!observedResult?.status && !observeProductTransactionResultError) {
    observedResultLoading = true;
  }

  if (observedResult?.status === 'success') {
    observedStatus = observedResult.status;
  }

  if (observedResult?.status === 'failed') {
    hasObservedResultError = true;

    if (observedResult.payload) {
      observedStatus = `
        Die Aktion kann nicht ausgeführt werden, da an folgenden Tagen eine Überbuchung entstehen würde: 
        ${observedResult.payload.join(', ')}
      `.trim();
    } else {
      observedStatus = 'Es ist ein unbekannter Fehler aufgetreten.';
    }
  }

  /**
   * Handlers
   */
  const handleChangeBikesAvailable = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const bikesAvailable = parseInt(e.target.value);
    setBikesAvailable(bikesAvailable);
  };

  const handleSaveBikesAvailable = async (productId: string) => {
    const startDate = availabilityStartDate ?? new Date();
    const endDate = availabilityEndDate ?? new Date();

    await createTransaction(productId, startDate, endDate);
  };

  const handleFullRecalculation = async (productId: string, startDate: string, endDate: string): Promise<void> => {
    await createTransaction(productId, parseISO(startDate), parseISO(endDate));
  };

  const createTransaction = async (
    productId: string,
    startDate: Date,
    endDate: Date
  ): Promise<void | never> => {
    try {
      // Create transactions for the products
      const transaction = await insertTransaction({
        variables: {
          type: 'updatedProductAvailability',
          payload: {
            startDate: defaultFormat(startDate),
            endDate: defaultFormat(endDate),
            available: bikesAvailable ?? product?.bikesAvailable,
          },
        },
      });
      const transactionId = transaction?.data?.insert_transactions?.returning?.shift()
        ?.id;

      const productTransaction = await insertProductTransactions({
        variables: {
          productTransactions: [
            { productId: productId, transactionId: transactionId },
          ],
        },
      });

      setProductTransactionId(
        productTransaction?.data?.insert_productTransactions?.returning?.shift()
          ?.id,
      );
      setShowBikesAvailableForm(false);
    } catch (e) {
      console.log(
        `Error when trying to change products availability: ${JSON.stringify(
          e,
        )}`,
      );
    }
  }

  if (!seasonsData || !data) {
    return (
      <Box className={classes.root}>
        {/** Component header */}
        <Grid container className={classes.content}>
          <Grid item xs={12}>
            <Typography variant='h2' gutterBottom>
              Produktkonfiguration laden...
            </Typography>
          </Grid>
        </Grid>

        <Box className={classes.content}>
          <CircularProgress color='secondary' />
        </Box>
      </Box>
    );
  }

  const seasons = seasonsData?.seasons;

  return (
    <>
      <Box className={classes.root}>
        {/** Component header */}
        <Grid container className={classes.content}>
          <Grid item xs={12}>
            <Typography variant='h2' gutterBottom>
              {product?.productType?.name} ({product?.size})
            </Typography>
          </Grid>
        </Grid>

        {/** Product data */}
        <Box className={classes.content}>
          <Typography variant='h3' gutterBottom>
            Verfügbarkeiten
          </Typography>
          <Paper className={classes.seasons}>
            <Typography variant={'h4'}>
              Saison
            </Typography>
            {seasons && seasons.map(season => {
              return (
                <Grid key={season.id} container>
                  <Grid item xs={8}>
                        <Typography variant={'body1'} key={season.id}>{season.name}</Typography>

                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      color={'secondary'}
                      endIcon={<WarningIcon />}
                      onClick={() => handleFullRecalculation(productId, season.startDate, season.endDate)}
                    >
                      Neu berechnen
                    </Button>
                  </Grid>
                </Grid>
              );
            })}
          </Paper>

          <Divider className={classes.divider} />

          <Paper className={classes.basicSettingsContainer}>
            <Grid container>
              <Grid item xs={5}>
                Aktuelle Buchungen:
              </Grid>
              <Grid item xs={3}>
                {product?.bookings_aggregate?.aggregate?.count}
              </Grid>
              <Grid item xs={4} />

              <Grid item xs={5}>
                Aktuell verfügbar:
              </Grid>
              <Grid item xs={3}>
                {
                  product?.snapshotAvailabilities_aggregate.aggregate?.sum
                    ?.availableBikes
                }
              </Grid>
              <Grid item xs={4} />

              {/*
              * Set availabilities for a date range
              */}
              <Grid item xs={5}>
                Max. verfügbar
              </Grid>
              {showBikesAvailableForm ? (
                <>
                  <Grid item xs={3}>
                    <Input
                      value={bikesAvailable}
                      placeholder={`${product?.bikesAvailable}` ?? '0'}
                      onChange={handleChangeBikesAvailable}
                      type={'number'}
                      className={classes.inputSmall}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      aria-label='cancel'
                      color='secondary'
                      onClick={() => setShowBikesAvailableForm(false)}
                      size={'small'}
                      startIcon={<CancelIcon fontSize={'small'} />}
                      className={classes.buttonSmall}
                    />
                    <Button
                      aria-label='save'
                      color='primary'
                      onClick={() => handleSaveBikesAvailable(product?.id)}
                      startIcon={<SaveIcon fontSize={'small'} />}
                      className={classes.buttonSmall}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={3}>
                    <span>{product?.bikesAvailable}</span>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      disabled={observedResultLoading}
                      aria-label='edit'
                      color='default'
                      onClick={() => setShowBikesAvailableForm(true)}
                      size={'small'}
                      startIcon={<EditIcon fontSize={'small'} />}
                      className={classes.buttonSmall}
                    />
                  </Grid>
                </>
              )}

              {showBikesAvailableForm && (
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
                  <Grid item xs={5}>
                    <DatePicker
                      disableToolbar
                      autoOk
                      variant='inline'
                      label='Startdatum'
                      value={availabilityStartDate}
                      onChange={date =>
                        setAvailabilityStartDate(date ?? new Date())
                      }
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <DatePicker
                      disableToolbar
                      autoOk
                      variant='inline'
                      label='Enddatum'
                      value={availabilityEndDate}
                      onChange={date =>
                        setAvailabilityEndDate(date ?? new Date())
                      }
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              )}
            </Grid>
          </Paper>
        </Box>

        <Box className={classes.spacer} />

        <Box className={classes.content}>
          <Typography variant='h3' gutterBottom>
            Aktueller Vorgang
          </Typography>

          <Divider className={classes.divider} />

          <Box>
            {observedStatus && !hasObservedResultError && (
              <Alert severity='success'>Status: {observedStatus}</Alert>
            )}

            {observedStatus && hasObservedResultError && (
              <Alert severity='error'>Fehler: {observedStatus}</Alert>
            )}

            {observedResultLoading && (
              <Alert
                icon={<CircularProgress color='secondary' size={'1em'} />}
                severity='info'
                style={{ maxWidth: '80%', overflow: 'hidden' }}
              >
                <>Wird verarbeitet...</>
              </Alert>
            )}

            {observeProductTransactionResultError && productTransactionId && (
              <Alert severity='error'>
                Fehler aufgetreten:{' '}
                {JSON.stringify(observeProductTransactionResultError)}
              </Alert>
            )}
          </Box>
        </Box>
        {/*
        <Box className={classes.spacer} />

        <Box className={classes.content}>
          <Typography variant="h3" gutterBottom>
            Frühere Vorgänge
          </Typography>

          <TableContainer>
            <Table
              stickyHeader
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell align={"left"} style={{width: '30%'}}>
                    Datum
                  </TableCell>

                  <TableCell align={"left"} style={{width: '30%'}}>
                    Vorgang
                  </TableCell>

                  <TableCell align={"left"}>
                    Infos
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align={"left"}>
                    2020-08-20 22:45
                  </TableCell>
                  <TableCell align={"left"}>
                    Verfügbarkeit aktualisiert
                  </TableCell>
                  <TableCell align={"left"}>
                    startDate: 2020-08-20<br/>
                    endDate: 2020-10-21<br />
                    available: 22
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align={"left"}>
                    2020-08-28 22:45
                  </TableCell>
                  <TableCell align={"left"}>
                    Verfügbarkeit aktualisiert
                  </TableCell>
                  <TableCell align={"left"}>
                    startDate: 2020-09-01<br/>
                    endDate: 2020-10-26<br />
                    available: 0
                  </TableCell>
                </TableRow>

              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        */}
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    divider: {
      marginTop: 0,
      marginBottom: '2em',
    },
    icon: {},
    buttonSmall: {
      minWidth: '30px',
      paddingLeft: '12px,',
      paddingTop: 0,
      paddingBottom: 0,
    },
    inputSmall: {
      marginTop: '-7px',
      paddingTop: 0,
      paddingBottom: 0,
    },
    content: {
      flexGrow: 1,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      marginTop: '1em',
    },
    productData: {},
    basicSettingsContainer: {
      padding: theme.spacing(2),

      width: '99%',
      '& .MuiGrid-item': {
        margin: 'auto',
        marginLeft: 0,
        minHeight: '2.5em',
        fontSize: '16px',
      },
    },
    seasons: {
      padding: theme.spacing(2),
    }
  }),
);

export default ProductSettings;
