import React, { FC, ReactElement } from 'react';
import MaterialTable from '@material-table/core';
import * as dateFns from 'date-fns';

import { Seasons } from '../../api/generated';

export type SeasonListItem = Pick<
  Seasons,
  'id' | 'name' | 'startDate' | 'endDate' | 'locationId'
>;

export interface ISeasonsListProps {
  data: SeasonListItem[];
  onSelect: (seasonId: string) => void;
}

const SeasonsList: FC<ISeasonsListProps> = ({
  data,
  onSelect,
}): ReactElement => {
  const formattedData: SeasonListItem[] = data.map(season => ({
    id: season.id,
    name: season.name,
    locationId: season.locationId,
    startDate: dateFns.format(new Date(season.startDate), 'dd.MM.Y'),
    endDate: dateFns.format(new Date(season.endDate), 'dd.MM.Y'),
  }))
  /**
   * Table configuration
   */
  const tableColumns = [
    { title: 'ID', field: 'id', hidden: true },
    { title: 'Name', field: 'name' },
    { title: 'Start', field: 'startDate' },
    { title: 'Ende', field: 'endDate' },
    { title: 'Filiale', field: 'location.name' },
  ];

  // @todo: It's a bit tricky to handle the multi-select feature of material-table here. If possible, simplify. Otherwise generalize and move to a shared space.
  const handleTableRowClick = (
    event: MouseEvent,
    items: SeasonListItem | SeasonListItem[],
  ) => {
    let item: SeasonListItem;

    if (Array.isArray(items)) {
      const firstItem = items.shift();

      if (!firstItem) {
        throw new Error('Multi selection of seasons not supported.');
      }

      item = firstItem;
    } else {
      item = items;
    }

    onSelect(item.id);
  };

  const tableActions = [
    {
      icon: 'edit',
      onClick: handleTableRowClick,
    },
  ];

  /**
   * Render seasons list
   */
  return (
    <MaterialTable<SeasonListItem>
      options={{
        showTitle: false,
        paging: true,
        search: true,
        toolbar: true,
        actionsColumnIndex: 99,
        pageSize: 10,
      }}
      columns={tableColumns}
      data={formattedData}
      actions={tableActions}
    />
  );
};

export default SeasonsList;
