import React, { ChangeEvent, FC, ReactElement } from 'react';
import {
  createStyles,
  fade,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import {
  AppBar,
  Button,
  Grid,
  Hidden,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useGlobalStyles } from '../styles/global';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Link as RouterLink } from 'react-router-dom';

import DateFnsUtils from '@date-io/date-fns';
import { addDays, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';
import GlobalDate from '../store/globalDate';
import SportIcon from '@material-ui/icons/SportsMotorsports';
import SeasonsIcon from '@material-ui/icons/CalendarViewDay';
import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined';
import BarChartOutlined from '@material-ui/icons/BarChartOutlined';
import Logout from './Logout';
import { useAuth0 } from '@auth0/auth0-react';
import GlobalLocation from '../store/globalLocation';
import { useGetLocationsQuery } from '../api/generated';
import Loader from './shared/Loader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBarButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      width: '70vw',
    },
    search: {
      display: 'none',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
      },
      marginRight: theme.spacing(3),
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    grow: {
      flexGrow: 1,
    },
    logo: {
      marginRight: '1em',
    },
    datePickerContainer: {
      width: '35em',
    },
    datePicker: {
      '& .MuiInput-formControl input': {
        textAlign: 'center',
        fontSize: '1.6em',
        paddingTop: '0.4em',
      }
      //width: '100%'
    },
    datePickerForward: {
      textAlign: 'left',
      fill: 'black',
    },
    datePickerBack: {
      textAlign: 'right',
      fill: 'black',
    },
    clearIcon: {
      color: theme.palette.primary.dark,
    },
  }),
);

const Header: FC = (): ReactElement => {
  const { isAuthenticated } = useAuth0();
  if (!isAuthenticated) return <></>;

  const globalDate = GlobalDate.useContainer();
  // const globalSearch = GlobalSearch.useContainer();
  const globalLocation = GlobalLocation.useContainer();

  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  /**
   * Handlers
   */
  const handleChangeLocation = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const locationId = e.target.value;
    globalLocation.set(locationId);
  }

  /**
   * GraphQL
   */
  const { data: locationsData, loading: locationsLoading } = useGetLocationsQuery();

  /**
   * Ensure all data is available
   */
  if (locationsLoading) {
    return <Loader />;
  }

  const locations = locationsData?.locations ?? [];

  /**
   * Set a default location if none is set
   */
  if (!globalLocation.locationId && locations?.length > 0) {
    globalLocation.set(locations[0].id);
  }

  return (
    <>
      <AppBar position='sticky'>
        <Toolbar>
          <Typography className={classes.title} variant='h6' noWrap>
            <span className={classes.logo}>
              {locations[0] && (
                <Select
                  id={'location'}
                  value={globalLocation.locationId}
                  defaultValue={locations[0].id}
                  onChange={handleChangeLocation}
                >
                  {locations && locations.map(location => (
                    <MenuItem
                      key={location.id}
                      value={location.id}
                    >
                      {location.name}
                    </MenuItem>
                  ))}

                </Select>
              )}
            </span>

            <Button
              color='default'
              variant={'outlined'}
              component={RouterLink}
              to='/'
              startIcon={<CalendarTodayOutlined />}
              className={classes.appBarButton}
            >
              <Hidden mdDown><span>Reservierungen</span></Hidden>
              <Hidden mdUp><span>&nbsp;</span></Hidden>
            </Button>

            <Button
              color='default'
              variant={'outlined'}
              component={RouterLink}
              to='/availabilities'
              startIcon={<BarChartOutlined />}
              className={classes.appBarButton}
            >
              <Hidden mdDown><span>Verfügbarkeiten</span></Hidden>
              <Hidden mdUp><span>&nbsp;</span></Hidden>
            </Button>

            <Button
              color='default'
              variant={'outlined'}
              component={RouterLink}
              to='/products'
              startIcon={<SportIcon />}
              className={classes.appBarButton}
            >
              <Hidden mdDown><span>Angebote</span></Hidden>
              <Hidden mdUp><span>&nbsp;</span></Hidden>
            </Button>

            <Button
              color='default'
              variant={'outlined'}
              component={RouterLink}
              to='/seasons'
              startIcon={<SeasonsIcon />}
              className={classes.appBarButton}
            >
              <Hidden mdDown><span>Saisonverwaltung</span></Hidden>
              <Hidden mdUp><span>&nbsp;</span></Hidden>
            </Button>

            {/*
                <Button>
                  <Add />
                </Button>
              */}
          </Typography>

          {/* Global Date Picker */}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
            <Grid container className={classes.datePickerContainer}>
              <Grid item xs={3} className={classes.datePickerBack}>
                <IconButton
                  color='default'
                  onClick={() =>
                    globalDate.set(addDays(parseISO(globalDate.date), -1))
                  }
                >
                  <ArrowBack />
                </IconButton>
              </Grid>

              <Grid item xs={6}>
                <DatePicker
                  autoOk
                  format={'cccccc, dd. MMMM'}
                  fullWidth
                  value={globalDate.date}
                  onChange={d => globalDate.set(d ?? new Date())}
                  className={classes.datePicker}
                />
              </Grid>

              <Grid item xs={3} className={classes.datePickerForward}>
                <IconButton
                  color='default'
                  onClick={() =>
                    globalDate.set(addDays(parseISO(globalDate.date), 1))
                  }
                >
                  <ArrowForward />
                </IconButton>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
          <div className={classes.grow} />
          <Logout />
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;