import { useGlobalStyles } from '../styles/global';
import { parseISO } from 'date-fns';
import {
  Button,
  Checkbox,
  createStyles,
  Switch,
  TableCell,
  TableRow,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { formatPrice } from '../helpers/price';
import { getBookingPrice } from '../helpers/order';
import GlobalDate from '../store/globalDate';
import BookingStatusIcon, { BookingStatusEnum } from './BookingStatusIcon';
import { Bookings } from '../api/generated';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    icon: {},
    priceCol: {
      textAlign: 'right',
      whiteSpace: 'nowrap',
    },
    booking: {},
    cancelled: {
      '& * ': {
        color: theme.palette.grey['500'],
      },
    },
  }),
);

type ReservationsTableProps = {
  orderEndDate: Date;
  bookings?: Pick<
    Bookings,
    | 'id'
    | 'created_at'
    | 'riderName'
    | 'riderWeight'
    | 'verifiedPriceSnapshot'
    | 'orderId'
    | 'includeProtection'
    | 'includeInsurance'
    | 'cancelled'
    | 'status'
  >[];
  selectedBookings: string[];
  onSelectBooking: (bookingId: string, checked: boolean) => void;
  onCancelBooking: (bookingId: string) => void;
  onChangeInsurance: (bookingId: string, selected: boolean) => void;
  onChangeProtection: (bookingId: string, selected: boolean) => void;
  onReturnBooking: (bookingIds: string[]) => void;
};

export default function BookingRows({
  bookings,
  selectedBookings,
  onSelectBooking,
  onChangeInsurance,
  onChangeProtection,
  onCancelBooking,
  onReturnBooking,
  orderEndDate,
}: ReservationsTableProps) {
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const globalDate = GlobalDate.useContainer();

  return (
    <>
      {bookings?.map(booking => {
        const bookingStatus = booking.status as BookingStatusEnum;

        // @todo: Check why there are Typescript errors. This is most probably a bug in our code!

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const productTypeName = booking.product.productType.name;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const productSize = booking.product.size;
        const riderName = booking.riderName;
        const riderWeight = booking.riderWeight;

        return (
          <TableRow key={booking.id}>
            <TableCell align={'center'}>
              <BookingStatusIcon
                bookingStatus={bookingStatus}
                overrides={
                  bookingStatus === BookingStatusEnum.NEW
                    ? [
                        {
                          status: BookingStatusEnum.NEW,
                          component: (
                            <Checkbox
                              checked={selectedBookings.includes(booking.id)}
                              onChange={(e, checked) =>
                                onSelectBooking(booking.id, checked)
                              }
                            />
                          ),
                        },
                      ]
                    : undefined
                }
              />
            </TableCell>

            <TableCell>
              {productTypeName} ({productSize})<br/>
              {riderWeight}kg {riderName && <>({riderName})</>}
            </TableCell>

            <TableCell align={'center'}>
              {/* Just crosssells of new Bookings can be changed */}
              {bookingStatus === BookingStatusEnum.NEW && (
                <>
                  <Switch
                    checked={booking.includeInsurance}
                    onChange={e =>
                      onChangeInsurance(booking.id, e.target.checked)
                    }
                  />
                </>
              )}

              {/* All other states just show indicator */}
              {bookingStatus !== BookingStatusEnum.NEW && (
                <>
                  {booking.includeInsurance ? (
                    <CheckCircleIcon color='disabled' />
                  ) : (
                    <CancelIcon color='disabled' />
                  )}
                </>
              )}
            </TableCell>

            <TableCell align={'center'}>
              {/* Just crosssells of new Bookings can be changed */}
              {bookingStatus === BookingStatusEnum.NEW && (
                <>
                  <Switch
                    checked={booking.includeProtection}
                    onChange={e => onChangeProtection(booking.id, e.currentTarget.checked)}
                  />
                </>
              )}

              {/* All other states just show indicator */}
              {bookingStatus !== BookingStatusEnum.NEW && (
                <>
                  {booking.includeProtection ? (
                    <CheckCircleIcon color='disabled' />
                  ) : (
                    <CancelIcon color='disabled' />
                  )}
                </>
              )}
            </TableCell>

            <TableCell align={'center'}>
              {/* Cancelled Bookings have no action here */}
              {bookingStatus === BookingStatusEnum.CANCELLED && <></>}

              {/* New Bookings can be cancelled */}
              {bookingStatus === BookingStatusEnum.NEW && (
                <Button
                  variant={'text'}
                  color={'secondary'}
                  onClick={() => onCancelBooking(booking.id)}
                >
                  Stornieren
                </Button>
              )}

              {/* Shipped bookings have a button to return */}
              {bookingStatus === BookingStatusEnum.SHIPPED &&
                !(orderEndDate > parseISO(globalDate.date)) && (
                  <Button
                    variant={'text'}
                    color={'secondary'}
                    onClick={() => onReturnBooking([booking.id])}
                  >
                    Rücknahme
                  </Button>
                )}

              {/* Shipped bookings which do not regularly end today have a button to return,
               * but with a better warning.
               */}
              {bookingStatus === BookingStatusEnum.SHIPPED &&
                orderEndDate > parseISO(globalDate.date) && (
                  <Button
                    variant={'text'}
                    color={'default'}
                    onClick={() => {
                      onReturnBooking ? onReturnBooking([booking.id]) : void 0;
                    }}
                  >
                    Vorzeitige Rücknahme
                  </Button>
                )}
            </TableCell>
            <TableCell className={classes.priceCol}>
              {bookingStatus === BookingStatusEnum.CANCELLED
                ? formatPrice(0.0)
                : formatPrice(getBookingPrice(booking))}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}
