import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useGetTransactionQuery } from '../api/generated';
import { formatPrice } from '../helpers/price';
import { getBookingPrice } from '../helpers/order';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    table: {},
    totalRow: {
      '& *': {
        fontSize: '1em',
      },
    },
    content: {
      flexGrow: 1,
      paddingTop: '10px',
      '& h1': {
        fontSize: '1.5em',
        textAlign: 'center',
      },
      '& h2': {
        fontSize: '1.1em',
        textAlign: 'center',
        marginTop: 0,
        fontWeight: 'normal',
      },
      '& h3': {
        fontSize: '1em',
        textAlign: 'center',
        marginTop: 0,
        fontWeight: 300,
      },
    },
    mainGrid: {
      marginTop: '2em',
      fontSize: '1em',
    },
    totalGrid: {
      marginTop: '2em',
      fontSize: '1.3em',
      fontWeigh: 'bold',
    },
    productNameCol: {
      fontSize: '1em',
    },
    priceCol: {
      verticalAlign: 'top',
      textAlign: 'right',
    },
    serialBox: {
      width: '100%',
      borderBottom: '1px dotted black',
    },
    customerGrid: {
      marginTop: '2em',
      textAlign: 'center',
      borderTop: '1px solid black',
    },
    orderDate: {
      fontWeight: 'bold',
      fontSize: '1.2em',
    },
    orderId: {
      fontWeight: 'normal',
      fontSize: '0.9em',
    },
    signature: {
      marginTop: '1em',
      borderBottom: '1px solid black',
      minHeight: '5em',
    },
  }),
);

type CustomerRecipeProps = {
  transactionId: string;
};

export default function CustomerRecipe({ transactionId }: CustomerRecipeProps) {
  const classes = {
    ...useStyles(),
  };

  const { data, loading, error } = useGetTransactionQuery({
    variables: {
      id: transactionId,
    },
  });

  // GraphQL data
  const transaction = data?.transactions_by_pk;
  const bookings = transaction?.bookings_transactions.map(bt => bt.booking);
  const extraRecipeLines = transaction?.extraRecipeLinesTransactions;
  // Get order from first booking. We can do this, because all bookings belong to the same order
  const order = transaction?.bookings_transactions.shift()?.booking.order;
  const customer = order?.customer;
  const bookingsTotal =
    bookings?.reduce((total, booking) => total + getBookingPrice(booking), 0) ??
    0.0;
  const extraRecipeLinesTotal =
    extraRecipeLines?.reduce(
      (total, line) => total + line.extraRecipeLine.amount,
      0,
    ) ?? 0.0;
  const transactionTotal = bookingsTotal + extraRecipeLinesTotal;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <Box className={classes.content}>
      <Typography variant='h1' gutterBottom>
        Kundenbeleg für:
      </Typography>
      <Typography variant='h2' gutterBottom>
        {customer?.name}
        <br />
      </Typography>
      <Typography variant='h3' gutterBottom>
        {customer?.phone}
        <br />
        {customer?.email}
        <br />
        {customer?.zipCode} {customer?.city}
        <br />
        {customer?.street} {customer?.streetnumber}
      </Typography>

      {/* TODO: Group by riderName? */}
      {bookings?.map(booking => {
        if (booking.status !== 'shipped') {
          return;
        }

        return (
          <React.Fragment key={booking.id}>
            <Grid container className={classes.mainGrid}>
              <Grid item xs={8} className={classes.productNameCol}>
                {booking.product.productType.name} ({booking.product.size})
                <br />
                {booking.includeProtection && (
                  <>
                    - inkl. Schutzausrüstung
                    <br />
                  </>
                )}
                {booking.includeInsurance && (
                  <>
                    - inkl. Versicherung
                    <br />
                  </>
                )}
              </Grid>

              <Grid item xs={4} className={classes.priceCol}>
                {formatPrice(getBookingPrice(booking))}
              </Grid>
            </Grid>
          </React.Fragment>
        );
      })}

      {extraRecipeLines?.map(line => {
        if (line.extraRecipeLine.status !== 'paid') {
          return;
        }

        return (
          <React.Fragment key={line.extraRecipeLine.id}>
            <Grid container className={classes.mainGrid}>
              <Grid item xs={8} className={classes.productNameCol}>
                {line.extraRecipeLine.description}
              </Grid>

              <Grid item xs={4} className={classes.priceCol}>
                {formatPrice(line.extraRecipeLine.amount)}
              </Grid>
            </Grid>
          </React.Fragment>
        );
      })}

      <Grid container className={classes.totalGrid}>
        <Grid item xs={6}>
          Summe
        </Grid>
        <Grid item xs={6} className={classes.priceCol}>
          {formatPrice(transactionTotal ?? 0)}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          Inkl. gesetzl. Mwst. 20%
        </Grid>
      </Grid>

      <Grid
        container
        className={classes.customerGrid}
        key={`${order?.id}-customer`}
      >
        <Grid item xs={12} className={classes.orderDate}>
          {order?.startDate} (Dauer: {order?.duration})
        </Grid>

        <Grid item xs={12} className={classes.orderId}>
          {order?.id}
        </Grid>
      </Grid>
    </Box>
  );
}
