import React, { FC } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Button,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { useGlobalStyles } from '../../styles/global';
import { CustomerCardFormProps } from './types/CustomerCard';

type FormValues = CustomerCardFormProps['customer'];

const CustomerCardForm: FC<CustomerCardFormProps> = ({
  customer,
  onSubmit = (data: FormValues): void => undefined,
  onCancel
}) => {
  // Styles
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const initialValues: FormValues = customer;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values: FormValues, { setSubmitting }): Promise<void> => {
        await onSubmit(values);
        setSubmitting(false);
      }}>

      {({ submitForm, isSubmitting }) => (
        <Form>
          <Field name="id" component={TextField} type="hidden" />
          <Grid container spacing={10}>
            <Grid container item xs={5} spacing={2} alignContent={'flex-start'}>
              <Grid item xs={12}>
                <Typography variant={'h4'}>
                  Persönliche Daten
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Field fullWidth name="firstname" component={TextField} type="text" label="Vorname" />
              </Grid>
              <Grid item xs={6}>
                <Field fullWidth name="name" component={TextField} type="text" label="Nachname" />
              </Grid>

              <Grid item xs={8}>
                <Field fullWidth name="street" component={TextField} type="text" label="Straße" />
              </Grid>
              <Grid item xs={4}>
                <Field
                  fullWidth name="streetnumber" component={TextField} type="text" label="Hausnummer" />
              </Grid>

              <Grid item xs={8}>
                <Field fullWidth name="city" component={TextField} type="text" label="Ort" />
              </Grid>
              <Grid item xs={4}>
                <Field fullWidth name="zipCode" component={TextField} type="text" label="Postleitzahl" />
              </Grid>
              <Grid item xs={12}>
                <Field fullWidth name="countryCode" component={TextField} type="text" label="Land" />
              </Grid>
            </Grid>

            <Grid container item xs={4} spacing={2} alignContent={'flex-start'}>
              <Grid item xs={12}>
                <Typography variant={'h4'}>
                  Kontakt
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Field fullWidth name="email" component={TextField} type="text" label="E-Mail" />
              </Grid>
              <Grid item xs={12}>
                <Field fullWidth name="phone" component={TextField} type="text" label="Telefon" />
              </Grid>
            </Grid>

            <Grid container item xs={3} alignContent={'flex-start'}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography variant={'h4'}>
                    Bemerkungen
                  </Typography>
                </Grid>

                <Field
                  component={TextField}
                  name="notes"
                  type="text"
                  label="Bemerkung (intern)"
                  aria-label="customer notes"
                  multiline
                  rows={6}
                  variant={'outlined'}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Speichern {isSubmitting && <LinearProgress />}
              </Button>

              <Button
                color="default"
                disabled={isSubmitting}
                onClick={onCancel}
              >
                Abbrechen
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: 18,
  },
});

export default CustomerCardForm;