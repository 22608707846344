// State management
import { createContainer } from 'unstated-next';
import { useState } from 'react';
import * as dateFns from 'date-fns';
import { INTERNAL_DATE_FORMAT } from '../helpers/date';

function useGlobalDate(initialState = new Date()) {
  const [date, setDate] = useState(
    dateFns.format(initialState, INTERNAL_DATE_FORMAT),
  );
  const set = (date: Date) => {
    const newDate = dateFns.format(date, INTERNAL_DATE_FORMAT);
    return setDate(newDate);
  };

  return { date, set };
}

const GlobalDate = createContainer(useGlobalDate);

export default GlobalDate;
