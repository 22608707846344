import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useGlobalStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      paddingTop: 70,
      paddingBottom: 50,
      // '& .MuiTextField-root': {
      //   marginBottom: theme.spacing(1),
      // },
    },
    appBar: {
      top: 'auto',
      bottom: 0,
      '& .MuiButtonBase-root': {
        marginTop: '1em',
      },
      padding: theme.spacing(2),
      paddingTop: 0,
      backgroundColor: '#000000',
    },
    appRoot: {
      textAlign: 'left',
      padding: theme.spacing(2),
    },
    divider: {
      marginTop: '2em',
      marginBottom: '2em',
    },
    spacer: {
      width: '100%',
      height: '2em',
    },
    content: {
      flexGrow: 1,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      marginTop: '5em',
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginTop: '1em',
      },
    },
  }),
);
