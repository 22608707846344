import { formatPrice } from './price';

export const getBookingPrice = (booking: any): number => {
  const priceSnapshot = JSON.parse(booking.verifiedPriceSnapshot);
  const productPrice = priceSnapshot?.product?.data?.price;

  let total = 0.0;

  if (!productPrice) {
    console.error(`No price data found!`);
  }

  const insurancePrice =
    booking.includeInsurance === true ? productPrice.insuranceCostsPerDay : 0;
  const protectionPrice =
    booking.includeProtection === true ? productPrice.protectionCostsPerDay : 0;

  total = productPrice.basePricePerDay + insurancePrice + protectionPrice;

  const multiplier = booking.order.duration > 1 ? booking.order.duration : 1;
  return total * multiplier;
};

export const getOrderTotalString = (order: any): string => {
  const bookingPrices = order.bookings.map((booking: any) => {
    if (booking.status === 'cancelled') {
      return 0.0;
    }

    return getBookingPrice(booking);
  });

  const extraRecipePrices =
    order.extraRecipeLines?.map((line: any) => {
      return line.amount;
    }) ?? [];

  const totalBookingCosts = bookingPrices.reduce(
    (prev: number, cur: number) => prev + cur,
    0.0,
  );
  const extraRecipeCosts = extraRecipePrices.reduce(
    (prev: number, cur: number) => prev + cur,
    0.0,
  );
  const total = totalBookingCosts + extraRecipeCosts;

  return formatPrice(total);
};
