import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useGlobalStyles } from '../styles/global';
import Transaction from '../components/PrintTransaction';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
  }),
);

interface IPrintTransactionParams {
  transactionId: string;
}

export default function PrintTransaction() {
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };
  const { transactionId } = useParams<IPrintTransactionParams>();

  return <Transaction transactionId={transactionId} />;
}
