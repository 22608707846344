import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useGlobalStyles } from '../styles/global';
import CustomerRecipe from '../components/PrintCustomerRecipe';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
  }),
);

interface IPrintCustomerRecipeParams {
  transactionId: string;
}

export default function PrintCustomerRecipe() {
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };
  const { transactionId } = useParams<IPrintCustomerRecipeParams>();

  return <CustomerRecipe transactionId={transactionId} />;
}
