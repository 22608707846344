import { Box } from '@material-ui/core';
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useGetBookingLabelQuery } from '../api/generated';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      paddingTop: '10px',
      '& h1': {
        fontSize: '1.5em',
        textAlign: 'center',
      },
      '& h2': {
        fontSize: '1.4em',
        textAlign: 'center',
        marginTop: 0,
        fontWeight: 'normal',
      },
      '& h3': {
        fontSize: '1.2em',
        textAlign: 'left',
        marginTop: 0,
        fontWeight: 300,
      },
    },
  }),
);

type BookingLabelProps = {
  bookingId: string;
};

export default function BookingLabel({ bookingId }: BookingLabelProps) {
  const classes = {
    ...useStyles(),
  };

  const { data, loading, error } = useGetBookingLabelQuery({
    variables: {
      id: bookingId,
    },
  });

  // GraphQL data
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  if (!data) return <p>Loading...</p>;

  const booking = data.bookings_by_pk;

  const riderName = (booking?.riderName ?? '') !== '' ? booking?.riderName : 'K/A'

  return (
    <Box className={classes.content}>
      <div className={'label'}>
        <Typography variant='h1' gutterBottom>
          {booking?.product.productType.name}
          <br />
        </Typography>
        <Typography variant='h2' gutterBottom>
          Größe: {booking?.product.size}
          <br />
        </Typography>
        <Typography variant='h3' gutterBottom>
          Gewicht: {booking?.riderWeight}kg
          <br />
          Rider: {riderName}
          <br />
          Mieter: {booking?.order.customer.firstname} {booking?.order.customer.name}
        </Typography>
      </div>
      <div className={'page-break'} />
    </Box>
  );
}
