import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { ChangeEvent, FC, ReactElement, useState } from 'react';
import { useGlobalStyles } from '../../styles/global';
import { Products, useUpsertProductMutation } from '../../api/generated';
import {
  Box,
  Button, Grid,
  TextField,
} from '@material-ui/core';
import GlobalLocation from '../../store/globalLocation';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';

export type IProductVariant =
  Partial<Pick<Products, 'id' | 'locationId' | 'bikesAvailable' | 'size' | 'description' | 'sortPosition'>>
  & Pick<Products, 'productTypeId'>;

export interface IProductVariantFormProps {
  /** Variant can be undefined to create a new variant */
  variant: IProductVariant;
}


const ProductVariantForm: FC<IProductVariantFormProps> = ({
  variant: variantData,
}): ReactElement => {
  /**
   * Styles
   */
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  /** States */
  const globalLocation = GlobalLocation.useContainer();
  const locationId = globalLocation.locationId;

  const newVariantDefaults: IProductVariant = {
    id: variantData?.id,
    productTypeId: variantData?.productTypeId,
    locationId: variantData?.locationId ?? locationId,
    size: variantData?.size ?? '',
    description: variantData?.description ?? '',
    bikesAvailable: variantData?.bikesAvailable ?? 0,
    sortPosition: variantData?.sortPosition ?? 0,
  };
  const [variant, setVariant] = useState<IProductVariant>(variantData?.id ? variantData : newVariantDefaults);

  /**
   * GraphQL
   */
  const [upsertProduct, {loading}] = useUpsertProductMutation()

  /** Handlers */
  const handleVariantUpdate = async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void> => {
    const field = e.currentTarget.id;
    const value = e.currentTarget.value;

    setVariant({
      ...variant,
      [field]: value,
    });
  };

  const handleSubmitNewVariant = async (): Promise<void> => {
    await upsertProduct({
      variables: {
        data: {
          id: variant.id,
          productTypeId: variant.productTypeId,
          locationId: variant.locationId,
          bikesAvailable: variant.bikesAvailable,
          size: variant.size,
          description: variant.description,
          sortPosition: variant.sortPosition,
        }
      }
    });
  }

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={6}>
          <TextField
            id={'size'}
            value={variant.size}
            label={'Ausprägung'}
            onChange={handleVariantUpdate}
            disabled={!!variant.id}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id={'description'}
            value={variant.description}
            label={'Beschreibung'}
            onChange={handleVariantUpdate}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id={'sortPosition'}
            value={variant.sortPosition}
            label={'Position in Sortierung'}
            onChange={handleVariantUpdate}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            type={'number'}
            value={variant.bikesAvailable}
            id='bikesAvailable'
            label={'Verfügbare Produkte'}
            onChange={handleVariantUpdate}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={8} />
        <Grid item xs={4}>
          <Button
            color='primary'
            variant={'outlined'}
            onClick={handleSubmitNewVariant}
            disabled={loading || '' === variant.size || !variant.bikesAvailable }
            size={'small'}
            className={classes.variantAddButton}
            endIcon={!variant.id ? <AddIcon fontSize={'small'} /> : <SaveIcon fontSize={'small'}/>}
            fullWidth
          >
            {!variant.id ? 'Erstellen' : 'Aktualisieren'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
    },
    variantAddButton: {
      marginTop: '1em'
    }
  }),
);

export default ProductVariantForm;
