import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useGetTransactionQuery } from '../api/generated';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    table: {},
    totalRow: {
      '& *': {
        fontSize: '1.2em',
      },
    },
    content: {
      flexGrow: 1,
      paddingTop: '10px',
      '& h1': {
        fontSize: '2em',
        textAlign: 'center',
      },
      '& h2': {
        fontSize: '1.3em',
        textAlign: 'center',
        marginTop: 0,
        fontWeight: 'normal',
      },
      '& h3': {
        fontSize: '1em',
        textAlign: 'center',
        marginTop: 0,
        fontWeight: 300,
      },
    },
    mainGrid: {
      marginTop: '2em',
      fontSize: '1.5em',
    },
    checkbox: {
      border: '1px solid black',
      width: '1.5em',
      height: '1.5em',
      marginTop: '0.5em',
    },
    checkboxCol: {
      verticalAlign: 'top',
    },
    productNameCol: {
      fontSize: '0.8em',
    },
    priceCol: {
      verticalAlign: 'top',
      textAlign: 'right',
    },
    serialBox: {
      width: '100%',
      borderBottom: '1px dotted black',
    },
    customerGrid: {
      marginTop: '2em',
      textAlign: 'center',
      borderTop: '1px solid black',
    },
    orderDate: {
      fontWeight: 'bold',
      fontSize: '1.2em',
    },
    orderId: {
      fontWeight: 'normal',
      fontSize: '0.9em',
    },
    signature: {
      marginTop: '1em',
      borderBottom: '1px solid black',
      minHeight: '5em',
    },
  }),
);

type TransactionProps = {
  transactionId: string;
};

export default function Transaction({ transactionId }: TransactionProps) {
  const classes = {
    ...useStyles(),
  };

  const { data, loading, error } = useGetTransactionQuery({
    variables: {
      id: transactionId,
    },
  });

  // GraphQL data
  const transaction = data?.transactions_by_pk;
  const bookings = transaction?.bookings_transactions.map(bt => bt.booking);
  // Get order from first booking. We can do this, because all bookings belong to the same order
  const order = transaction?.bookings_transactions.shift()?.booking.order;
  const customer = order?.customer;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <Box className={classes.content}>
      <Typography variant='h1' gutterBottom>
        Ausgabe für:
      </Typography>
      <Typography variant='h2' gutterBottom>
        {customer?.name}
        <br />
      </Typography>
      <Typography variant='h3' gutterBottom>
        {customer?.phone}
        <br />
        {customer?.email}
        <br />
        {customer?.zipCode} {customer?.city}
        <br />
        {customer?.street} {customer?.streetnumber}
      </Typography>

      {/* TODO: Group by riderName? */}
      {bookings?.map((booking: any) => {
        if (booking.status !== 'shipped') {
          return;
        }

        return (
          <React.Fragment key={booking.id}>
            <Grid container className={classes.mainGrid}>
              <Grid item xs={3} className={classes.checkboxCol}>
                <Box className={classes.checkbox}></Box>
              </Grid>

              <Grid item xs={9} className={classes.productNameCol}>
                <Box className={classes.serialBox}>#</Box>
                {booking.product.productType.name} ({booking.product.size})
              </Grid>

              {/*
              <Grid item xs={5} className={classes.priceCol}>
                { formatPrice(getBookingPricePerDay(booking)) }
              </Grid>
              */}
            </Grid>

            {booking.includeProtection && (
              <Grid
                container
                className={classes.mainGrid}
                key={`${booking.id}-safety`}
              >
                <Grid item xs={3} className={classes.checkboxCol}>
                  <Box className={classes.checkbox}></Box>
                </Grid>

                <Grid item xs={9} className={classes.productNameCol}>
                  {booking.product.productType.name} Schutzausrüstung
                </Grid>

                {/*
                <Grid item xs={5} className={classes.priceCol}>
                  { formatPrice(getBookingPricePerDay(booking)) }
                </Grid>
                */}
              </Grid>
            )}
          </React.Fragment>
        );
      })}

      <Grid
        container
        className={classes.customerGrid}
        key={`${order?.id}-customer`}
      >
        <Grid item xs={12} className={classes.orderDate}>
          {order?.startDate} (Dauer: {order?.duration})
        </Grid>

        <Grid item xs={12} className={classes.orderId}>
          {order?.id}
        </Grid>
      </Grid>

      <Grid container className={classes.customerGrid}>
        <Grid item xs={12}>
          <strong>Ich akzeptiere die Miet- und Nutzungsbedingungen.</strong>
          <br />
          <strong>I accept the terms of use and rental conditions.</strong>
        </Grid>

        <Grid item xs={12} className={classes.signature}>
          Unterschrift:
          <br />
        </Grid>
      </Grid>
    </Box>
  );
}
