import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    h1: {
      fontSize: '2.5em',
      fontFamily: 'Raleway, sans-serif',
      paddingBottom: 0,
      marginBottom: '0.75em',
      marginTop: '1em',
      //borderBottom: '1px solid #81d644',
      textAlign: 'left',
    },
    h2: {
      fontSize: '2em',
      fontFamily: 'Raleway, sans-serif',
      paddingBottom: 0,
      marginBottom: '0.75em',
      marginTop: '1em',
      //borderBottom: '1px solid #81d644',
      textAlign: 'left',
    },
    h3: {
      fontSize: '1.5em',
      fontFamily: 'Raleway, sans-serif',
      paddingBottom: 0,
      marginBottom: '0.5em',
      marginTop: '0.75em',
      //borderBottom: '1px solid #81d644',
      textAlign: 'left',
    },
    h4: {
      fontSize: '1.2em',
      fontFamily: 'Raleway, sans-serif',
      paddingBottom: 0,
      marginBottom: '0.5em',
      marginTop: '0.75em',
      //borderBottom: '1px solid #81d644',
      textAlign: 'left',
    },
    h5: {
      fontSize: '1em',
      fontFamily: 'Raleway, sans-serif',
      marginTop: '1.5em',
      marginBottom: '1em',
    },
  },
  shape: {
    borderRadius: 4,
  },
  palette: {
    primary: {
      main: '#81d644',
    },
    secondary: {
      main: '#17a4ef',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
});

export default theme;
