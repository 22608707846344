import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, ReactElement, useState } from 'react';
import { useGlobalStyles } from '../../styles/global';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';

import {
  ProductPrices_Insert_Input,
  useDeleteProductPriceMutation,
  useObserveProductsByProductTypeSubscription,
  useUpsertProductPricesMutation,
} from '../../api/generated';
import Loader from '../shared/Loader';
import ProductPriceForm, { IPrice } from './ProductPriceForm';
import AddIcon from '@material-ui/icons/Add';
import ProductVariantForm from './ProductVariantForm';
import GlobalLocation from '../../store/globalLocation';

export interface IProductFormProps {
  productTypeId: string;
}

const ProductForm: FC<IProductFormProps> = ({ productTypeId }): ReactElement => {
  const globalLocation = GlobalLocation.useContainer();
  /**
   * Styles
   */
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  /** Local state */
  const [showNewProductPriceForm, setShowNewProductPriceForm] = useState<string | undefined>();

  /**
   * GraphQL
   */
  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
  } = useObserveProductsByProductTypeSubscription({
    variables: {
      productTypeId: productTypeId,
      locationId: globalLocation.locationId
    },
  });

  const [upsertProductPrice, { loading: upsertProductPriceLoading }] = useUpsertProductPricesMutation();
  const [deleteProductPrice] = useDeleteProductPriceMutation();

  /**
   * Error and loading logic
   */
  if (productsLoading) {
    return <Loader />;
  }

  if (productsError) {
    return <>Error</>;
  }

  /**
   * Prepare local data
   */
  const products = productsData?.products?.map(product => {
    return {
      ...product,
      // Convert price to EUR
      productPrices: product.productPrices.map(price => ({
        id: price.id,
        productId: price.productId,
        minDuration: price.minDuration,
        basePricePerDay: (price.basePricePerDay ?? 0) / 100 / 100,
        insuranceCostsPerDay: (price.insuranceCostsPerDay ?? 0) / 100 / 100,
        protectionCostsPerDay: (price.protectionCostsPerDay ?? 0) / 100 / 100,
      }))
    };
  })

  /**
   * Handlers
   */

  /** Save product price */
  const onSubmitPrice = async (price: IPrice): Promise<void> => {
    await upsertProductPrice({ variables: { prices: [_getPriceVariables(price)] } });
    setShowNewProductPriceForm(undefined);
  }

  const onDeletePrice = async (priceId: string): Promise<void> => {
    await deleteProductPrice({ variables: { id: priceId } });
  }

  const handleShowNewProductPriceForm = (productId: string) => {
    setShowNewProductPriceForm(productId);
  }

  return (
    <Box className={classes.root}>
      <Typography
        variant={'h3'}
        className={classes.variantsHeader}
      >
        Produktvarianten
      </Typography>

      <Paper className={classes.paper}>
        <Typography className={classes.formText}>
          <strong>Produktvariante erstellen:</strong><br />
          Achtung: Der Name der Variante kann im Nachhinein nicht mehr geändert werden.
        </Typography>
        <ProductVariantForm variant={{ productTypeId: productTypeId}} />
      </Paper>

      <Typography
        variant={'h4'}
        className={classes.variantsHeader}
      >
        Liste der Varianten
      </Typography>

      {products && products.map(product => {
        return (
          <Paper key={product.id} className={classes.paper}>
            <Typography variant={'h4'}>Variante: {product.size}</Typography>

            <ProductVariantForm variant={product} />

            <Paper className={classes.paper}>
              <Grid container className={classes.priceHeader}>
                <Grid item xs={10}>
                  <Typography variant={'h5'}>Preise</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    color='primary'
                    variant={'outlined'}
                    aria-label='add'
                    onClick={() => handleShowNewProductPriceForm(product.id)}
                    size={'small'}
                    endIcon={<AddIcon fontSize={'small'} />}
                    fullWidth
                  >
                  </Button>
                </Grid>
              </Grid>

              {showNewProductPriceForm === product.id && (
                <ProductPriceForm
                  key={'new Product'}
                  price={{ productId: product.id }}
                  onSubmit={onSubmitPrice}
                  onCancel={() => setShowNewProductPriceForm(undefined)}
                />
              )}

              {product.productPrices && product.productPrices.map(price => {
                return (
                  <ProductPriceForm
                    key={price.id}
                    price={price}
                    onSubmit={onSubmitPrice}
                    onDelete={onDeletePrice}
                    isLoading={upsertProductPriceLoading}
                  />
                );
              })}
            </Paper>
          </Paper>
        );
      })}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    variantsRoot: {
      borderColor: theme.palette.grey['A100'],
      borderWidth: '3px',
    },
    paper: {
      marginTop: '1em',
      padding: theme.spacing(2),
    },
    submit: {},
    priceHeader: {
      padding: '0.5em 0',
      '& h5': {
        margin: 0,
      }
    },
    variantsHeader: {
      marginBottom: '1em',
      marginTop: '3em',
    },
    formText: {
      marginBottom: '1em',
    }
  }),
);

const _getPriceVariables = (price: IPrice): ProductPrices_Insert_Input => {
  return {
    id: price.id,
    productId: price.productId,
    minDuration: price.minDuration,
    basePricePerDay: Math.round((price.basePricePerDay ?? 0) * 100 * 100),
    insuranceCostsPerDay: Math.round((price.insuranceCostsPerDay ?? 0) * 100 * 100),
    protectionCostsPerDay: Math.round((price.protectionCostsPerDay ?? 0) * 100 * 100),
  }
};

export default ProductForm;