import React, { FC, ReactElement, useState } from 'react';
import { Box, Button, Divider, Hidden, Input, TextField, Typography } from '@material-ui/core';

import { ISeason } from './types';
import { ClosingDates } from './ClosingDates';
import {
  useDeleteClosingDateMutation,
  useInsertClosingDateMutation,
  useObserveClosingDatesByDateRangeSubscription,
} from '../../api/generated';
import GlobalLocation from '../../store/globalLocation';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useGlobalStyles } from '../../styles/global';
import DateFnsUtils from '@date-io/date-fns';
import { de } from 'date-fns/locale';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

export interface ISeasonFormProps {
  data: ISeason;
  onSubmit: (season: ISeason) => void;
}

/*
    drawerContent: {
      padding: theme.spacing(5),
      width: '33vw',
      minWidth: '400px',
      overflowY: 'scroll',
    },
*/

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    createProductTypeButton: {
      position: 'absolute',
      right: theme.spacing(10),
      top: theme.spacing(15),
    },
    root: {
      padding: theme.spacing(5),
      width: '33vw',
      minWidth: '400px',
      overflowY: 'scroll',

    },
    form: {
      marginBottom: '2em',
      '& > div': {
        marginBottom: '1em',
      },
    },
    closingDatesWrapper: {
      marginTop: '2em'
    }
  }),
);

const SeasonForm: FC<ISeasonFormProps> = ({
  data: season,
  onSubmit,
}): ReactElement => {
  /**
   * Styles
   */
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const [formData, setFormData] = useState<ISeason>(season);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const globalLocation = GlobalLocation.useContainer();
  const locationId = globalLocation.locationId;

  const {
    data: closingDatesData,
  } = useObserveClosingDatesByDateRangeSubscription({
    variables: {
      startDate: season.startDate,
      endDate: season.endDate,
      locationId: locationId,
    },
  });

  const [deleteClosingDateMutation] = useDeleteClosingDateMutation();
  const [insertClosingDateMutation] = useInsertClosingDateMutation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);
    try {
      await onSubmit(formData);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }

    setLoading(false);
  };

  const handleChange = (key: string, value: string | number) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleDeleteClosingDate = async (id: string) => {
    await deleteClosingDateMutation({
      variables: {
        id,
      },
    });
  };

  const handleInsertClosingDate = async (date: Date) => {
    await insertClosingDateMutation({
      variables: {
        date,
        locationId,
      },
    });
  };

  // @todo: add loading and error handling

  const closingDates = closingDatesData?.closingDates;

  return (
    <Box className={classes.root}>
      <Typography variant={'h3'}>Saison {formData.id ? 'bearbeiten' : 'anlegen'}</Typography>
      <form  className={classes.form} onSubmit={handleSubmit}>
        <Hidden xsUp>
          <Input
            name={'id'}
            type={'hidden'}
            value={formData.id}
            onChange={e => {
              handleChange(e.currentTarget.name, e.currentTarget.value);
            }}
          />
        </Hidden>

        <TextField
          name={'name'}
          value={formData.name}
          onChange={e => {
            handleChange(e.currentTarget.name ?? '', e.currentTarget.value);
          }}
          label='Saisonname'
          placeholder='Summer of 69"'
          fullWidth
        />

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
          <DatePicker
            disableToolbar
            autoOk
            format={'dd.MM.Y'}
            variant='inline'
            label='Start'
            value={formData.startDate}
            name={'startDate'}
            onChange={(e: Date) => {
              handleChange('startDate', e.toISOString());
            }}
            fullWidth
          />
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
          <DatePicker
            disableToolbar
            autoOk
            format={'dd.MM.Y'}
            variant='inline'
            label='Ende'
            value={formData.endDate}
            name={'endDate'}
            onChange={(e: Date) => {
              handleChange('endDate', e.toISOString());
            }}
            fullWidth
          />
        </MuiPickersUtilsProvider>

        {error && <Box>Error {error}</Box>}

        <Button
          type='submit'
          color={'primary'}
          variant={'contained'}
          disabled={loading}
        >
          Saison speichern
        </Button>
      </form>

      <Divider />

      {closingDates && (
        <Box className={classes.closingDatesWrapper}>
          <Typography variant={'h4'}>Geschlossene Tage konfigurieren</Typography>
          <ClosingDates
            closingDates={closingDates}
            onDeleteDate={handleDeleteClosingDate}
            onInsertDate={handleInsertClosingDate}
            minDate={season.startDate}
            maxDate={season.endDate}
          />
        </Box>
      )}
    </Box>
  );
};

export default SeasonForm;
