import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

interface IErrorProps {
  error: Error;
}

const Error: React.FC<IErrorProps>= ({error}) => {
  return (
    <Alert>
      <AlertTitle>Error</AlertTitle>
      <pre>
        {JSON.stringify(error, null, 2)}
      </pre>
    </Alert>
  );
};

export default Error;
