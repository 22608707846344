import { useGlobalStyles } from '../styles/global';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  createStyles,
  IconButton,
  Input,
  TableCell,
  TableRow,
  Theme,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ExtraRecipeLines } from '../api/generated';
import { formatPrice } from '../helpers/price';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    icon: {},
  }),
);

export enum ModeEnum {
  DISPLAY = 'display',
  INSERT = 'insert',
  UPDATE = 'update',
}

type LineProps = {
  initialLine: Partial<ExtraRecipeLines>;
  mode: ModeEnum;
  selected: boolean;
  onUpdate: (line: Partial<ExtraRecipeLines>) => void;
  onDelete: (lineId: string) => void;
  // @todo: Not implemented
  onCancel: () => void;
  onSelect: (lineId: string, selected: boolean) => void;
};

export default function ExtraRecipeLine({
  initialLine,
  mode,
  selected,
  onUpdate,
  onDelete,
  onCancel,
  onSelect,
}: LineProps) {
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const [line, setLine] = useState({ ...initialLine });

  const handleChange = (key: string, value: string | number) => {
    setLine({
      ...line,
      [key]: value,
    });
  };

  const handleOnUpdate = async () => {
    // Reset local state an trigger update of parent.
    // @todo: Check why subscription of order does not update status of extraRecipeLine when onUpdate was triggered.
    const originalLine = line;
    await setLine({});
    await onUpdate(originalLine);
  };

  const actions = {
    [ModeEnum.INSERT]: (
      <>
        <IconButton
          aria-label='close-recipe-line-form'
          color='primary'
          onClick={() => {
            setLine({});
            onCancel ? onCancel() : void 0;
          }}
        >
          <CancelIcon color={'error'} />
        </IconButton>

        <IconButton
          aria-label='save-recipe-line'
          color='primary'
          onClick={handleOnUpdate}
        >
          <SaveIcon color={'primary'} />
        </IconButton>
      </>
    ),
    [ModeEnum.DISPLAY]: (
      <>
        <IconButton
          aria-label='save-recipe-line'
          color='primary'
          onClick={() => {
            onDelete ? onDelete(line.id) : void 0;
          }}
        >
          <DeleteIcon color={'error'} />
        </IconButton>
      </>
    ),
    [ModeEnum.UPDATE]: <>setMode to update</>,
  };

  return (
    <>
      <TableRow key={initialLine?.id}>
        <TableCell align={'left'}>
          {line.status === 'new' && (
            <>
              <Checkbox
                checked={selected}
                onChange={(e, checked) => {
                  onSelect(line.id, checked);
                }}
              />
            </>
          )}

          {line.status === 'paid' && (
            <>
              <CheckCircleIcon color='disabled' />
            </>
          )}
        </TableCell>

        <TableCell align={'left'}>
          {['update', 'insert'].includes(mode) && (
            <Input
              name={'description'}
              onChange={e => handleChange(e.target.name, e.target.value)}
              defaultValue={line?.description}
              fullWidth={true}
            />
          )}

          {['display'].includes(mode) && <>{line?.description}</>}
        </TableCell>

        <TableCell align={'center'}>
          {line?.status !== 'paid' && actions[mode]}
        </TableCell>

        <TableCell align={'right'}>
          {['update', 'insert'].includes(mode) && (
            <Input
              name={'amount'}
              onChange={e => handleChange(e.target.name, e.target.value)}
              defaultValue={line?.amount ?? 0.0}
              type={'number'}
            />
          )}

          {['display'].includes(mode) && (
            <>{formatPrice(line?.amount ?? 0.0)}</>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
