// State management
import { createContainer } from 'unstated-next';
import { useState } from 'react';

function useGlobalSearch(initialState = '') {
  const [term, setTerm] = useState(initialState);
  const set = (term: string) => {
    return setTerm(`${term}`);
  };

  return { term, set };
}

const GlobalSearch = createContainer(useGlobalSearch);

export default GlobalSearch;
