import React, { FC } from 'react';
import {
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useGlobalStyles } from '../../styles/global';
import { CustomerCardProps } from './types/CustomerCard';

const CustomerCardData: FC<CustomerCardProps> = ({ customer }) => {
  // Styles
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography variant={'h4'} gutterBottom>
          {customer.firstname} {customer.name}
        </Typography>
        <Typography variant="body1" component="p">
          {customer.street} {customer.streetnumber}
          <br />
          {customer.zipCode} {customer.city}
          <br />
          {customer.countryCode}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant={'h4'} gutterBottom>
          Kontakt
        </Typography>
        <Typography variant="body1" component="p">
          <a href={'mailto:' + customer.email}>{customer.email}</a>
          <br />
          <a href={'tel:' + customer.email}>{customer.phone}</a>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant={'h4'} gutterBottom>
          Bemerkungen (intern)
        </Typography>
        <Typography variant="body1" component="p" className={classes.description}>
          {customer.notes}
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  description: {
    whiteSpace: 'pre',
  }
});

export default CustomerCardData;