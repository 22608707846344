import React, { ReactElement } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useGlobalStyles } from '../../styles/global';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({}),
);

type AlertModalProps = {
  alertContent: ReactElement,
  alertTitle?: string,
  entityId?: string,
  onCancel: (entityId?: string) => void,
  onAccept: (entityId?: string) => void,
};

export default function AlertModal({ onCancel, onAccept, alertContent, alertTitle, entityId }: AlertModalProps) {
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={() => onCancel(entityId)}
      >
        {alertTitle && (
          <DialogTitle>{alertTitle}</DialogTitle>
        )}

        <DialogContent>
          <DialogContentText>
            {alertContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onCancel(entityId)} color="default" variant={'contained'}>
            Abbrechen
          </Button>
          <Button onClick={() => onAccept(entityId)} color="primary" variant={'contained'} autoFocus>
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
