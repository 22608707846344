import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useGlobalStyles } from '../styles/global';
import BookingLabel from '../components/PrintBookingLabel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
  }),
);

interface IPrintBookingLabelParams {
  bookingId: string;
}

export default function PrintBookingLabel() {
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };
  const { bookingId } = useParams<IPrintBookingLabelParams>();

  return <BookingLabel bookingId={bookingId} />;
}
