// State management
import { createContainer } from 'unstated-next';
import { useState } from 'react';

function useGlobalLocation() {
  const [locationId, setLocationId] = useState<string | undefined>();
  const set = (locationId?: string) => {
    return setLocationId(locationId);
  };

  return { locationId, set };
}

const GlobalLocation = createContainer(useGlobalLocation);

export default GlobalLocation;
