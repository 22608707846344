import React, { FC, ReactElement } from 'react';
import MaterialTable from '@material-table/core';
import { ProductTypes } from '../../api/generated';

// export type ProductTypeListItem = Pick<ProductTypes, 'id' | 'name' | 'category' | 'products_aggregate'>;
export type ProductTypeListTableItem = Partial<Pick<ProductTypes, 'id' | 'name' | 'category'>>
  & { amountProducts: number };

export interface IProductTypesListProps {
  data: ProductTypeListTableItem[];
  onSelect: (productTypeId: string) => void;
}

const ProductTypesList: FC<IProductTypesListProps> = ({
  data,
  onSelect,
}): ReactElement => {
  /**
   * Table configuration
   */
  const tableColumns = [
    { title: 'ID', field: 'id', hidden: true },
    { title: 'Name', field: 'name' },
    { title: 'Kategorie', field: 'category' },
    { title: 'Anzahl Produkte', field: 'amountProducts' },
  ];

  // @todo: It's a bit tricky to handle the multi-select feature of material-table here. If possible, simplify. Otherwise generalize and move to a shared space.
  const handleTableRowClick = (
    event: MouseEvent,
    items: ProductTypeListTableItem | ProductTypeListTableItem[],
  ) => {
    let item: ProductTypeListTableItem;

    if (Array.isArray(items)) {
      const firstItem = items.shift();

      if (!firstItem) {
        throw new Error('Multi selection of productTypes not supported.');
      }

      item = firstItem;
    } else {
      item = items;
    }

    onSelect(item.id);
  };

  const tableActions = [
    {
      icon: 'edit',
      onClick: handleTableRowClick,
    },
  ];

  /**
   * Render product types list
   */
  return (
    <MaterialTable<ProductTypeListTableItem>
      options={{
        showTitle: false,
        paging: true,
        search: true,
        toolbar: true,
        actionsColumnIndex: 99,
        pageSize: 10,
      }}
      columns={tableColumns}
      data={data}
      actions={tableActions}
    />
  );
};

export default ProductTypesList;
