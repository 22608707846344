export const formatPrice = (price: number): string => {
  return `€ ${Math.round(price / (100 * 100)).toFixed(2)}`;
};

export const priceStringWithDuration = (
  price: number,
  duration: number,
): string => {
  const durationString = duration === 0.5 ? '½' : duration;
  const priceString = formatPrice(price);
  const dayString = `${priceString} (${durationString} Tag${
    duration > 1 ? 'e' : ''
  })`;

  return dayString;
};

export const priceTotalForDuration = (
  price: number,
  duration: number,
): number => {
  const multiplier = duration <= 1 ? 1 : duration;
  const result = price * multiplier;

  return result;
};
