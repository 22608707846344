


import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { ChangeEvent, FC, ReactElement, useState } from 'react';
import { useGlobalStyles } from '../../styles/global';
import { ProductPrices } from '../../api/generated';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/DeleteForever';

export type IPrice =
  Partial<Pick<ProductPrices, 'id' | 'basePricePerDay' | 'insuranceCostsPerDay' | 'protectionCostsPerDay' | 'minDuration'>>
  & Pick<ProductPrices, 'productId'>;

export interface IProductPriceFormProps {
  /** Price can be undefined to create a new price */
  price: IPrice;
  onSubmit: (price: IPrice) => void;
  onDelete?: (priceId: string) => void;
  onCancel?: () => void;
  isLoading?: boolean;
}


const ProductPriceForm: FC<IProductPriceFormProps> = ({
  price: priceData,
  onSubmit,
  onDelete = () => undefined,
  onCancel = () => undefined,
  isLoading = false,
} ): ReactElement => {
  /**
   * Styles
   */
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  /**
   * Helpers
   */
  const getTotalPriceRounded = (basePricePerDay: number, duration: number) => {
    let totalPrice: number;

    if (duration <= 1) {
      totalPrice = basePricePerDay;
    } else {
      totalPrice = basePricePerDay * duration;
    }

    return Math.round(totalPrice);
  }

  const getBasePricePerDay = (totalPrice: number, duration: number) => {
    if (duration <= 1) {
      return totalPrice;
    } else {
      return totalPrice / duration;
    }
  }

  /** States */
  const newPriceDefaults: IPrice = {
    productId: priceData?.productId,
    basePricePerDay: 0.00,
    insuranceCostsPerDay: 0.00,
    protectionCostsPerDay: 0.00,
    minDuration: 0,
  };
  const [price, setPrice] = useState<IPrice>(priceData.id ? priceData : newPriceDefaults);
  const [totalPrice, setTotalPrice] = useState<number>(priceData.id ? getTotalPriceRounded(priceData?.basePricePerDay ?? 0, priceData.minDuration) : 0);
  const [totalInsurancePrice, setTotalInsurancePrice] = useState<number>(priceData.id ? getTotalPriceRounded(priceData?.insuranceCostsPerDay ?? 0, priceData.minDuration) : 0);
  const [totalProtectionPrice, setTotalProtectionPrice] = useState<number>(priceData.id ? getTotalPriceRounded(priceData?.protectionCostsPerDay ?? 0, priceData.minDuration) : 0);

  /** Handlers */
  const handleDurationUpdate = async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void> => {
    const duration = isNaN(parseFloat(e.currentTarget.value)) ? 0 : parseFloat(e.currentTarget.value);

    setPrice({
      ...price,
      minDuration: duration,
    });
  };

  const handleTotalPriceUpdate = async (priceType: string, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void> => {
    const totalPriceValue = isNaN(parseFloat(e.currentTarget.value)) ? 0.00 : parseFloat(e.currentTarget.value);

    switch (priceType) {
      case 'basePricePerDay':
        setTotalPrice(totalPriceValue);
        break;

      case 'insuranceCostsPerDay':
        setTotalInsurancePrice(totalPriceValue);
        break;

      case 'protectionCostsPerDay':
        setTotalProtectionPrice(totalPriceValue);
        break;

      default:
        console.error('price type ' + priceType + '  not configured');
    }

    const priceValue = getBasePricePerDay(totalPriceValue, price.minDuration);

    setPrice(price => ({
      ...price,
      [priceType]: priceValue,
    }));
  };

  const handleSubmit = async (): Promise<void> => {
    await onSubmit(price)
  }

  const handleDelete = async (): Promise<void> => {
    onDelete(price.id);
  }

  const handleCancel = () => {
    onCancel();
  }

  /**
   * Defaults
   */
  const isNew = !price.id;
  const isNewFeature = isNew ? true : undefined;

  return (
      <Accordion
        key={price?.id ?? 'new-price'}
        expanded={isNewFeature}
        square={isNewFeature}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id={'panel-' + price?.id ?? 'new-price'}
        >
          <Typography className={classes.priceHeading}>Gültig ab {price.minDuration} Tage(n)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.pricesContainer}>
            <Grid container className={classes.priceForm} spacing={2}>

              {/* Duration */}
              <Grid item xs={12}>
                <TextField
                  id='minDuration'
                  type={'number'}
                  value={price.minDuration}
                  label={'Gültig ab (Tage)'}
                  onChange={handleDurationUpdate}
                  fullWidth
                />
              </Grid>

              {/* Total price */}
              <Grid item xs={6}>
                <TextField
                  id='totalPrice'
                  type={'number'}
                  value={totalPrice}
                  label={'Produktpreis'}
                  onChange={(e) => handleTotalPriceUpdate('basePricePerDay', e)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id='basePricePerDay'
                  type={'number'}
                  disabled
                  value={price.basePricePerDay}
                  label={'Basispreis (berechnet)'}
                />
              </Grid>

              {/* Insurance */}
              <Grid item xs={6}>
                <TextField
                  id='totalInsurancePrice'
                  type={'number'}
                  value={totalInsurancePrice}
                  label={'Versicherung'}
                  onChange={(e) => handleTotalPriceUpdate('insuranceCostsPerDay', e)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id='insuranceCostsPerDay'
                  type={'number'}
                  disabled
                  value={price.insuranceCostsPerDay}
                  label={'Basispreis (berechnet)'}
                />
              </Grid>


              {/* Upsells */}
              <Grid item xs={6}>
                <TextField
                  id=''
                  type={'number'}
                  value={totalProtectionPrice}
                  label={'Schutzausrüstung'}
                  onChange={(e) => handleTotalPriceUpdate('protectionCostsPerDay', e)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id='protectionCostsPerDay'
                  type={'number'}
                  disabled
                  value={price.protectionCostsPerDay}
                  label={'Basispreis (berechnet)'}
                />
              </Grid>
              <Grid item xs={12}>

              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
        <AccordionActions>
          <Button
            color={'secondary'}
            variant={isNew ? 'outlined' : 'contained'}
            onClick={isNew ? handleCancel : handleDelete}
          >
            {!isNewFeature && <DeleteIcon /> }

            {isNewFeature ? 'Abbrechen' : 'Löschen' }
          </Button>

          <Button
            type={'submit'}
            color={'primary'}
            variant={'contained'}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Preis {isNew ? 'speichern' : 'aktualisieren'}
          </Button>
        </AccordionActions>
      </Accordion>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      '& > div': {
        width: '100%',
        marginBottom: '1em',
      },
    },
    submit: {},

    pricesContainer: {},
    priceForm: {},
    priceHeading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),

);

export default ProductPriceForm;
