import React from 'react';
import { Tooltip } from '@material-ui/core';
import DirectionsBike from '@material-ui/icons/DirectionsBike';
import { FiberNew, HighlightOff } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export enum BookingStatusEnum {
  NEW = 'new',
  CANCELLED = 'cancelled',
  SHIPPED = 'shipped',
  RETURNED = 'returned',
}

const BookingStatusTranslations = {
  de: {
    [BookingStatusEnum.NEW]: 'Abholbereit.',
    [BookingStatusEnum.CANCELLED]: 'Storniert',
    [BookingStatusEnum.SHIPPED]: 'Ausgegeben',
    [BookingStatusEnum.RETURNED]: 'Zurückgenommen. Wieder im Shop verfügbar.',
  },
};

const BookingStatusIcons = {
  [BookingStatusEnum.NEW]: (
    <>
      <FiberNew color='primary' />
    </>
  ),
  [BookingStatusEnum.CANCELLED]: (
    <>
      <HighlightOff color='disabled' />
    </>
  ),
  [BookingStatusEnum.SHIPPED]: (
    <>
      <DirectionsBike color='secondary' />
    </>
  ),
  [BookingStatusEnum.RETURNED]: (
    <>
      <CheckCircleIcon color='disabled' />
    </>
  ),
};

type BookingStatusIconProps = {
  bookingStatus: BookingStatusEnum;
  overrides?: [
    {
      status: BookingStatusEnum;
      component: JSX.Element;
    },
  ];
};

export default function BookingStatusIcon({
  bookingStatus,
  overrides,
}: BookingStatusIconProps) {
  const icons = Object.assign({}, BookingStatusIcons);

  if (!!overrides && Array.isArray(overrides)) {
    overrides.map(o => {
      icons[o.status] = o.component;
    });
  }

  return (
    <>
      <Tooltip title={BookingStatusTranslations.de[bookingStatus]}>
        <span>{icons[bookingStatus]}</span>
      </Tooltip>
    </>
  );
}
